import {
  SignupFormData,
  SignupPayload
} from "../redux/sitka/signup/signup_state"
import { PathContext } from "../redux/sitka/bootstrap/bootstrap_state"

export const buildNewUserPayload = (
  values: SignupFormData,
  pathContext: PathContext
): SignupPayload => {
  const { email, firstName, lastName } = values

  if (pathContext.invited && pathContext.teamID) {
    const { invited, user, teamID } = pathContext
    const signupData = {
      email: email.toLowerCase(),
      name: `${firstName} ${lastName}`,
      firstName,
      lastName,
      invited,
      user,
      teamID
    }
    return signupData
  }

  const signupData = {
    email,
    name: `${firstName} ${lastName}`,
    firstName,
    lastName
  }
  return signupData
}
